import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../../utils/redux/createAppAsyncThunk';
import { fetchAdfoxHomeService } from '../../services/fetchAdfoxParams';
import { ELoadingStatus } from '../../types/loadingStatus';
import { TCianApiSiteV1AdfoxHomeResponse_1 } from '../../repositories/monolith-python/cian-api/site/v1/adfox/home';

const initialState = {
  status: ELoadingStatus.Loading,
  data: null as TCianApiSiteV1AdfoxHomeResponse_1 | null,
};

export const fetchPuids = createAppAsyncThunk('puids/fetch', (_, { extra: context }) => {
  return fetchAdfoxHomeService(context, { sectionType: '1' });
});

export const { reducer: puidsReducer } = createSlice({
  name: 'puids',
  reducers: {},
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchPuids.pending, state => {
      state.status = ELoadingStatus.Loading;
    });
    builder.addCase(fetchPuids.fulfilled, (state, { payload }) => {
      state.status = ELoadingStatus.Success;
      state.data = payload;
    });
    builder.addCase(fetchPuids.rejected, state => {
      state.status = ELoadingStatus.Failure;
    });
  },
});

import * as React from 'react';
import { Label } from '@cian/ui-kit';

import { ICompilation } from '../../../types/newbuildingBanners/compilationBanner';
import { TBannerTheme } from '../utils/bannerTypeToTheme';
import { Banner } from '../Banner/Banner';
import { Providers } from '../Banner/Providers';
import { RoundButtonLink } from '../Banner/RoundButtonLink';

interface ICompilationBannerProps extends ICompilation {
  theme: TBannerTheme;
  onClick(): void;
}

export const CompilationBanner = ({
  label,
  description,
  providers,
  url,
  title,
  theme,
  onClick,
}: ICompilationBannerProps) => {
  return (
    <Banner
      onClick={onClick}
      linkUrl={url}
      theme={theme}
      labels={
        label ? (
          <Label background="decorative-theme-white" color="text-primary-default">
            {label}
          </Label>
        ) : null
      }
      subtitle={description}
      footer={
        <>
          <Providers providers={providers} />
          <RoundButtonLink url={url} onClick={onClick} />
        </>
      }
      title={title}
    />
  );
};

import { UIHeading1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './recommendationsSection.css';

interface IRecommendationsSection {
  title: string;
  watchAll: React.ReactElement;
}

export const RecommendationsSection = ({
  title,
  children,
  watchAll,
}: React.PropsWithChildren<IRecommendationsSection>) => {
  return (
    <section
      data-testid="NewbuildingRecommendations"
      data-name="NewbuildingRecommendations"
      className={styles['section']}
    >
      <div className={styles['header']}>
        <UIHeading1>{title}</UIHeading1>
        <div className={styles['action']}>{watchAll}</div>
      </div>
      <div className={styles['content']}>
        <div className={styles['recommendations']}>{children}</div>
      </div>
    </section>
  );
};

import * as React from 'react';

import { RecommendationsList } from '../../components/RecommendationsList';
import { RecommendationsListDesktop } from '../../components/RecommendationsListDesktop';
import { useRecommendations } from './useRecommendations';
import { ELoadingStatus } from '../../types/loadingStatus';
import { RecommendationSkeleton } from '../../components/Recommendation';
import { TRecommendation } from '../../types/recommendation';
import { RecommendationDesktop, RecommendationDesktopSkeleton } from '../../components/RecommendationDesktop';
import { FavoriteButtonContainer } from '../FavoriteButtonContainer';
import { LabelsContainer } from '../LabelsContainer';
import { trackEntityTeaserShow } from './tracking';
import { RecommendationsListMobile } from './RecommendationsListMobile';
import { RecommendationsListDesktopWithNewbuildings } from '../../components/RecommendationsListDesktopWithNewbuildings';
import { NewbuildingRecommendations } from '../NewbuildingRecommendations/NewbuildingRecommendations';
import { useSelector } from 'react-redux';
import { selectIsIndexLandingType } from '../../selectors/landingType';
import { useApplicationContext } from '../../utils/applicationContext';

export function RecommendationsListContainer() {
  const { config } = useApplicationContext();
  const recommendationsControls = useRecommendations();
  const showRecommendations =
    useSelector(selectIsIndexLandingType) || config.get('NewbuildingRecommendations.ShowOnVerticals.Enabled');
  const {
    recommendations,
    onRecommendationClick,
    onShow,
    onShowTrap,
    showHeader,
    recommendationsStatus,
    isMobile,
    regionId,
    onMoreClick,
    isOfferVisited,
  } = recommendationsControls;

  const recommendationsMobileSkeleton = React.useMemo(
    () =>
      new Array(18)
        .fill(0)
        .map((_, index) => <RecommendationSkeleton key={`recommendation-mobile-skeleton-${index}`} />),
    [],
  );

  const recommendationsDesktopSkeleton = React.useMemo(
    () =>
      new Array(8)
        .fill(0)
        .map((_, index) => <RecommendationDesktopSkeleton key={`recommendation-desktop-skeleton-${index}`} />),
    [],
  );

  const handleEntityTrackTeaserShow = React.useCallback((offerId: number | null, position: number) => {
    if (!offerId) {
      return;
    }

    trackEntityTeaserShow(offerId, position, isMobile);
  }, []);

  if (recommendationsStatus === ELoadingStatus.Loading) {
    return isMobile ? (
      <RecommendationsList withHeader={showHeader}>{recommendationsMobileSkeleton}</RecommendationsList>
    ) : (
      <RecommendationsListDesktop>{recommendationsDesktopSkeleton}</RecommendationsListDesktop>
    );
  }

  if (!recommendations.length) {
    return null;
  }

  return isMobile ? (
    <RecommendationsListMobile
      recommendationsControls={recommendationsControls}
      onTrack={handleEntityTrackTeaserShow}
    />
  ) : !showRecommendations ? (
    <RecommendationsListDesktop onShow={onShow} onMoreClick={onMoreClick}>
      {recommendations.map((recommendation: TRecommendation, index) => (
        <RecommendationDesktop
          onTrack={handleEntityTrackTeaserShow}
          position={index + 1}
          key={recommendation.offerId}
          onClick={onRecommendationClick}
          onShowTrap={onShowTrap}
          regionId={regionId}
          favoriteButton={<FavoriteButtonContainer size="M" id={recommendation.offerId} />}
          labels={<LabelsContainer visited={isOfferVisited(recommendation.offerId)} />}
          {...recommendation}
        />
      ))}
    </RecommendationsListDesktop>
  ) : (
    <RecommendationsListDesktopWithNewbuildings
      secondRow={<NewbuildingRecommendations />}
      onShow={onShow}
      onMoreClick={onMoreClick}
    >
      {recommendations.map((recommendation: TRecommendation, index) => (
        <RecommendationDesktop
          onTrack={handleEntityTrackTeaserShow}
          position={index + 1}
          key={recommendation.offerId}
          onClick={onRecommendationClick}
          onShowTrap={onShowTrap}
          regionId={regionId}
          favoriteButton={<FavoriteButtonContainer size="M" id={recommendation.offerId} />}
          labels={<LabelsContainer visited={isOfferVisited(recommendation.offerId)} />}
          {...recommendation}
        />
      ))}
    </RecommendationsListDesktopWithNewbuildings>
  );
}

import { withBrokerExperimentTitle } from './internal';
import { IPrepareBannersAdditionalParams } from './types';
import {
  IPromoBannerSchema,
  TType,
} from '../repositories/newbuilding-promos/entities/get_promo_banners/PromoBannerSchema';
import { IV1GetPromoBannersResponseSchema } from '../repositories/newbuilding-promos/entities/get_promo_banners/V1GetPromoBannersResponseSchema';
import { TBanners, TBannerTypes } from '../types/newbuildingBanners/banners';
import { IPromoBanner, TPromoBannerType } from '../types/newbuildingBanners/promoBanner';

// TODO: Удалить в CD-231816 в обоих константах баннер флайта и при аппруве баннер КпН.
const BANNERS_WHITELIST = ['allPromos', 'kpn', 'promosFlight', 'lastChanceDiscount', 'topPromos', 'newbuildingBroker'];
const BANNERS_WEIGHT = {
  lastChanceDiscount: 10,
  topPromos: 10,
  allPromos: 10,
  kpn: 10,
  promosFlight: 10_000,
  newbuildingBroker: 120,
};

const getWeightRandomBannerTypes = ({ currentTypes }: { currentTypes: TBannerTypes[] }): TBannerTypes[] => {
  let currentBannerWeights = currentTypes.map(type => ({
    type,
    weight: BANNERS_WEIGHT[type],
  }));

  const bannerTypes: TBannerTypes[] = [];

  while (bannerTypes.length < currentTypes.length) {
    const totalWeights = currentBannerWeights.reduce((accumulator, value) => accumulator + value.weight, 0);
    let random = Math.random() * totalWeights;

    for (let i = 0; i < currentBannerWeights.length; i++) {
      const banner = currentBannerWeights[i];
      if (random < banner.weight) {
        bannerTypes.push(banner.type);
        currentBannerWeights = currentBannerWeights.filter(item => {
          return item.type !== banner.type;
        });
      }
      random = random - banner.weight;
    }
  }

  return bannerTypes;
};

function assertBannerType(bannerType: TType): asserts bannerType is TPromoBannerType {
  if (!BANNERS_WHITELIST.includes(bannerType)) {
    throw new Error(`Unknown banner type: ${bannerType}`);
  }
}

export const prepareBanner = (banner: IPromoBannerSchema): IPromoBanner | null => {
  try {
    const bannerType: IPromoBannerSchema['type'] = banner.type;
    assertBannerType(bannerType);

    return {
      action: {
        link: banner.action.link,
        title: banner.action.title,
      },
      imageUrl: banner.imageUrl,
      subtitle: banner.subtitle,
      title: banner.title,
      type: bannerType,
    };
  } catch (error) {
    return null;
  }
};

//Возвращает массив баннеров в порядке их ранжирования по весам
export const prepareBanners = (
  banners: IV1GetPromoBannersResponseSchema,
  { config, isNewbuildingBrokerExperimentEnabled }: IPrepareBannersAdditionalParams,
): TBanners[] => {
  const currentTypes = [] as TBannerTypes[];
  const mappedBanners = {} as Record<TBannerTypes, TBanners>;
  const allPromos = prepareBanner(banners.allPromos);
  const consultant = prepareBanner(banners.consultant);
  const newbuildingBroker = prepareBanner(banners.newbuildingBroker);

  /* istanbul ignore else */
  if (allPromos) {
    currentTypes.push(allPromos.type);
    mappedBanners[allPromos.type] = allPromos;
  }

  // TODO: Удалить в CD-231816 фича-флаг при аппруве.
  const newbuildingBrokerEnabled = config.get<boolean>('recommendations-micro-frontend.newbuildingBrokerBannerEnabled');

  if (newbuildingBroker && newbuildingBrokerEnabled) {
    const expNewbuildingBroker = withBrokerExperimentTitle(newbuildingBroker, isNewbuildingBrokerExperimentEnabled);
    currentTypes.push(expNewbuildingBroker.type);
    mappedBanners[expNewbuildingBroker.type] = expNewbuildingBroker;
  } else if (consultant) {
    currentTypes.push(consultant.type);
    mappedBanners[consultant.type] = consultant;
  }

  /* istanbul ignore else */
  if (banners.compilations) {
    banners.compilations.forEach(compilation => {
      currentTypes.push(compilation.type);
      mappedBanners[compilation.type] = compilation;
    });
  }

  const order: TBannerTypes[] = getWeightRandomBannerTypes({ currentTypes });

  return order.map((item: TBannerTypes) => mappedBanners[item]);
};

import * as React from 'react';
import { UIHeading4, UIText2 } from '@cian/ui-kit';

import * as styles from './recommendationCard.css';

interface ICardProps {
  name: string;
  mediaUrl: string;
  minPrice: string;
  status: string;
  underground: React.ReactElement | null;
  address?: string | null;
  reviews: React.ReactElement | null;
  url: string;
  onClick(): void;
}

export const RecommendationCard = ({
  name,
  mediaUrl,
  minPrice,
  status,
  reviews,
  underground,
  url,
  address,
  onClick,
}: ICardProps) => {
  return (
    <a
      data-testid="NewbuildingRecommendationCard"
      data-name="NewbuildingRecommendationCard"
      href={url}
      target="_blank"
      className={styles['card']}
      onClick={onClick}
      rel="noreferrer"
    >
      <img className={styles['image']} src={mediaUrl} alt={name} />
      <div className={styles['content']}>
        <div className={styles['title']}>
          <UIText2 color="primary_100" whiteSpace="nowrap">
            {name}
          </UIText2>
        </div>
        <div className={styles['details']}>
          {reviews && <div className={styles['reviews']}>{reviews}</div>}
          <div className={styles['status']}>{status}</div>
        </div>
        {!!minPrice && (
          <div className={styles['price']}>
            <UIHeading4>{minPrice}</UIHeading4>
          </div>
        )}
        {underground && <div>{underground}</div>}
        {address && (
          <div>
            <UIText2 display="inline" color="text-secondary-default">
              {address}
            </UIText2>
          </div>
        )}
      </div>
    </a>
  );
};

import { IPromoBanner } from '../../types/newbuildingBanners/promoBanner';

// TODO: В CD-231816 удалить.
export const withBrokerExperimentTitle = (banner: IPromoBanner, isNewbuildingBrokerExperimentEnabled: boolean) => {
  if (isNewbuildingBrokerExperimentEnabled) {
    return {
      ...banner,
      title: 'Бесплатно подберём и\u00A0поможем купить квартиру',
    };
  }

  return banner;
};

import * as React from 'react';
import { Image, LinkButton, Theme, UIHeading3 } from '@cian/ui-kit';

import { IPromoBanner } from '../../../types/newbuildingBanners/promoBanner';
import { TBannerTheme } from '../utils/bannerTypeToTheme';
import { Banner } from '../Banner/Banner';

import * as styles from './NewbuildingBrokerBanner.css';

interface INewbuildingBrokerBannerProps extends IPromoBanner {
  onClick(): void;
  theme: TBannerTheme;
}

const DEFAULT_ACTION_TEXT = 'О сервисе';

export const NewbuildingBrokerBanner = ({ action, imageUrl, title, theme, onClick }: INewbuildingBrokerBannerProps) => {
  return (
    <Banner
      onClick={onClick}
      theme={theme}
      title=""
      imageUrl={null}
      labels={null}
      subtitle=""
      linkUrl={action.link}
      customClassName={styles['custom-container']}
      customContent={
        <div className={styles['custom-content']}>
          <Image src={imageUrl ?? ''} objectFit="contain" height="auto" />
          <div className={styles['title']}>
            <UIHeading3 color="text-inverted-default">{title}</UIHeading3>
          </div>
        </div>
      }
      footer={
        <div className={styles['footer-button']}>
          <Theme theme="colored">
            <LinkButton target="_blank" href={action.link} onClick={onClick} fullWidth={true}>
              {action.title || DEFAULT_ACTION_TEXT}
            </LinkButton>
          </Theme>
        </div>
      }
    />
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { EQueryParameters } from '../../repositories/feed-page/v2/mobile-site/get-recommendations';

const initialState = EQueryParameters.MainPage;

const landingTypeSlice = createSlice({
  name: 'landingType',
  reducers: {},
  initialState,
});

export const landingTypeReducer = landingTypeSlice.reducer;

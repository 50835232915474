import * as React from 'react';

import { fetchNewbuildingRecommendations } from '../../slices';
import { useAppDispatch, useAppSelector as useSelector } from '../../types';

import { selectNewbuildingRecommendationsStatus, selectNewbuildingRecommendations } from '../../selectors/newbuildings';
import { fetchNewbuildingBanners } from '../../slices/newbuildingBanners/newbuildingBannersSlice';
import { selectNewbuildingBanners } from '../../selectors/newbuildings/selectNewbuildingBanners';
import {
  onBannerClickAnalytic,
  onBannerShowAnalytic,
  onCardClickAnalytic,
  onRecommendationsSectionShowAnalytic,
  onShowAllClickAnalytic,
} from './analytics/analytic';
import { TBannerTypes } from '../../types/newbuildingBanners/banners';

export const useNewbuildingRecommendations = () => {
  const dispatch = useAppDispatch();
  const recommendations = useSelector(selectNewbuildingRecommendations);
  const recommendationsStatus = useSelector(selectNewbuildingRecommendationsStatus);
  const banners = useSelector(selectNewbuildingBanners);

  const onRecommendationsShow = React.useCallback(() => {
    const products = recommendations.recommendations.map((item, idx) => ({ id: item.id, position: idx }));
    onRecommendationsSectionShowAnalytic(products);
  }, [recommendations]);

  const onShowAllClick = React.useCallback(() => {
    onShowAllClickAnalytic();
  }, []);

  const onCardClick = React.useCallback(
    ({ newbuildingId, position }: { newbuildingId: number; position: number }) =>
      () => {
        onCardClickAnalytic({ newbuildingId, position });
      },
    [],
  );

  const onBannerClick = React.useCallback(
    ({ type }: { type: TBannerTypes }) =>
      () => {
        onBannerClickAnalytic({ type });
      },
    [],
  );

  const onBannerShow = React.useCallback(
    ({ type }: { type: TBannerTypes }) =>
      () => {
        onBannerShowAnalytic({ type });
      },
    [],
  );

  React.useEffect(() => {
    dispatch(fetchNewbuildingRecommendations());
    dispatch(fetchNewbuildingBanners());
  }, [dispatch]);

  return React.useMemo(
    () => ({
      newbuildings: recommendations,
      banners,
      onRecommendationsShow,
      onCardClick,
      onShowAllClick,
      onBannerClick,
      recommendationsStatus,
      onBannerShow,
    }),
    [
      recommendations,
      banners,
      onRecommendationsShow,
      onCardClick,
      onShowAllClick,
      recommendationsStatus,
      onBannerClick,
      onBannerShow,
    ],
  );
};

// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IGetRecommendationsResponseV2Schema } from '../../../entities/recommendations/GetRecommendationsResponseV2Schema';
import type { IModelResponse, IModel } from '@cian/http-api';
import type { IErrorResponseSchema } from '../../../entities/schemas/ErrorResponseSchema';

export interface IDesktopGetRecommendationsRequest {
  /** Тип лэндинга **/
  landingType: EQueryParameters;
  /** Id региона пользователя **/
  regionId: number;
}

export enum EQueryParameters {
  /** Снять — квартиру на долгий срок **/
  'FlatRent' = 'flatRent',
  /** Посуточно — снять квартиру посуточно **/
  'FlatRentForDay' = 'flatRentForDay',
  /** Главная cian.ru **/
  'MainPage' = 'mainPage',
  /** Купить — квартиру во вторичке **/
  'SecondaryFlatSale' = 'secondaryFlatSale',
  /** Загородка — купить дом, дачу, таунхаус, участок **/
  'SuburbanSale' = 'suburbanSale',
}
export type TDesktopGetRecommendationsResponse_1 = IGetRecommendationsResponseV2Schema;

export interface IDesktopGetRecommendationsResponse200 extends IModelResponse<TDesktopGetRecommendationsResponse_1> {
  statusCode: 200;
}

export type TDesktopGetRecommendationsResponseError = IErrorResponseSchema;

export interface IDesktopGetRecommendationsResponse400 extends IModelResponse<TDesktopGetRecommendationsResponseError> {
  statusCode: 400;
}

export type TDesktopGetRecommendationsResponse =
  | IDesktopGetRecommendationsResponse200
  | IDesktopGetRecommendationsResponse400;

export type TDesktopGetRecommendationsModel = IModel<
  IDesktopGetRecommendationsRequest,
  TDesktopGetRecommendationsResponse
>;

export interface IMappers<TResponse200, TResponse400> {
  200(response: TDesktopGetRecommendationsResponse_1): TResponse200;
  400(response: TDesktopGetRecommendationsResponseError): TResponse400;
}

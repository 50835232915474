import * as React from 'react';

import { TBannerTheme } from '../../components/NewbuildingBanners/utils/bannerTypeToTheme';
import { PromosBanner } from '../../components/NewbuildingBanners/PromosBanner/PromosBanner';
import { ConsultantBanner } from '../../components/NewbuildingBanners/ConsultantBanner/ConsultantBanner';
import { CompilationBanner } from '../../components/NewbuildingBanners/CompilationBanner/CompilationBanner';
import { NewbuildingBrokerBanner } from '../../components/NewbuildingBanners/NewbuildingBrokerBanner/NewbuildingBrokerBanner';
import { TBanners, TBannerTypes } from '../../types/newbuildingBanners/banners';

interface IThemeMapperProps {
  type: TBannerTypes;
}

interface IUseBannerProp {
  themeMapper(props: IThemeMapperProps): TBannerTheme;
  data: TBanners;
  onClick(): void;
}

export const Banner = ({ themeMapper, data, onClick }: IUseBannerProp) => {
  // TODO: Удалить в CD-231816 баннер флайта и при аппруве баннер КпН.
  switch (data.type) {
    case 'allPromos':
      return <PromosBanner onClick={onClick} theme={themeMapper({ type: data.type })} {...data} />;
    case 'promosFlight':
      return <PromosBanner onClick={onClick} theme={themeMapper({ type: data.type })} {...data} />;
    case 'kpn':
      return <ConsultantBanner onClick={onClick} theme={themeMapper({ type: data.type })} {...data} />;
    case 'lastChanceDiscount':
      return <CompilationBanner onClick={onClick} theme={themeMapper({ type: data.type })} {...data} />;
    case 'topPromos':
      return <CompilationBanner onClick={onClick} theme={themeMapper({ type: data.type })} {...data} />;
    case 'newbuildingBroker':
      return <NewbuildingBrokerBanner onClick={onClick} theme={themeMapper({ type: data.type })} {...data} />;
  }
};

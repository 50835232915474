import * as React from 'react';
import { LinkButton } from '@cian/ui-kit';
import { Banner } from '../Banner/Banner';
import { TBannerTheme } from '../utils/bannerTypeToTheme';
import { IPromoBanner } from '../../../types/newbuildingBanners/promoBanner';

interface IConsultantBannerProps extends IPromoBanner {
  theme: TBannerTheme;
  onClick(): void;
}

const DEFAULT_ACTION_TEXT = 'Подобрать';

export const ConsultantBanner = ({ action, imageUrl, subtitle, title, theme, onClick }: IConsultantBannerProps) => {
  return (
    <Banner
      theme={theme}
      imageUrl={imageUrl}
      labels={null}
      subtitle={subtitle}
      onClick={onClick}
      linkUrl={action.link}
      footer={
        <LinkButton target="_blank" href={action.link} onClick={onClick} fullWidth={true}>
          {action.title || DEFAULT_ACTION_TEXT}
        </LinkButton>
      }
      title={title}
    />
  );
};

// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IOfferUndergroundSchema {
  /** Цвет линии метро, hex **/
  lineColor?: string;
  /** Название **/
  name: string;
  /** Время в пути в минутах до метро, мин **/
  time?: number;
  /** Способ передвижения до метро **/
  transportType?: ETransportType;
}

export enum ETransportType {
  /** На транспорте **/
  'Transport' = 'transport',
  /** Пешком **/
  'Walk' = 'walk',
}

import * as React from 'react';
import { LinkButton, Theme } from '@cian/ui-kit';
import { Banner } from '../Banner/Banner';
import { TBannerTheme } from '../utils/bannerTypeToTheme';
import { IPromoBanner } from '../../../types/newbuildingBanners/promoBanner';

interface IPromosBannerProps extends IPromoBanner {
  onClick(): void;
  theme: TBannerTheme;
}

const DEFAULT_ACTION_TEXT = 'Открыть';

export const PromosBanner = ({ action, imageUrl, subtitle, title, theme, onClick }: IPromosBannerProps) => {
  return (
    <Banner
      onClick={onClick}
      theme={theme}
      title={title}
      imageUrl={imageUrl}
      labels={null}
      subtitle={subtitle}
      linkUrl={action.link}
      footer={
        <Theme theme="colored">
          <LinkButton href={action.link} target="_blank" onClick={onClick} fullWidth={true}>
            {action.title || DEFAULT_ACTION_TEXT}
          </LinkButton>
        </Theme>
      }
    />
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { ELoadingStatus } from '../../types/loadingStatus';
import { getNewbuildingRecommendations } from '../../services/getNewbuildingRecommendations';
import { INewbuildingsRecommendations } from '../../types/newbuildingRecommendations';
import { createAppAsyncThunk } from '../../utils/redux/createAppAsyncThunk';

const defaultState = {
  recommendations: [],
  allRecommendations: { link: '' },
};

const initialState = {
  data: defaultState as INewbuildingsRecommendations,
  status: ELoadingStatus.Loading,
};

export const fetchNewbuildingRecommendations = createAppAsyncThunk(
  'newbuildingRecommendations/fetch',
  (_, { extra: context }) => {
    return getNewbuildingRecommendations(context, {});
  },
);

const newbuildingRecommendationsSlice = createSlice({
  name: 'newbuildingRecommendations',
  reducers: {},
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchNewbuildingRecommendations.pending, state => {
      state.status = ELoadingStatus.Loading;
    });
    builder.addCase(fetchNewbuildingRecommendations.fulfilled, (state, { payload }) => {
      state.status = ELoadingStatus.Success;
      state.data = payload;
    });
    builder.addCase(fetchNewbuildingRecommendations.rejected, state => {
      state.status = ELoadingStatus.Failure;
    });
  },
});

export const newbuildingRecommendationsReducer = newbuildingRecommendationsSlice.reducer;

import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import * as styles from './Recommendation.css';
import { UIHeading5, UIText2 } from '@cian/ui-kit';
import { useInView } from '@cian/valuation-utils-component';
import * as classnames from 'classnames';

import { TRecommendation } from '../../types/recommendation';
import { PriceDiscount } from '../PriceDiscount';

interface IRecommendationProps extends TRecommendation {
  onClick(analyticsData: object): void;
  onShowTrap(analyticsData: object): void;
  favoriteButton: React.ReactNode;
  labels: React.ReactNode;
  onTrack(offerId: number | null, position: number): void;
  position: number;
}

export const Recommendation: React.FC<IRecommendationProps> = ({
  imageUrl,
  title: price,
  description,
  analyticsData,
  offerId,
  href,
  transport,
  onClick,
  onShowTrap,
  favoriteButton,
  labels,
  onTrack,
  position,
  discount,
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (!offerId && inView) {
      onShowTrap(analyticsData);
    }
  }, [inView, offerId, analyticsData, onShowTrap]);

  return (
    <a
      ref={ref}
      onClick={() => onClick(analyticsData)}
      href={href}
      target="_blank"
      className={styles['container']}
      rel="noreferrer"
    >
      <ActionAfterViewObserver
        percentVisible={0.5}
        callback={() => onTrack(offerId, position)}
        triggerOnce={false}
        callbackDelay={1000}
      >
        <>
          {labels}
          <span className={styles['favorite']}>{favoriteButton}</span>
          <img src={imageUrl} className={styles['image']} />
          <div className={styles['price']}>
            <UIHeading5>{discount ? discount.newPrice : price}</UIHeading5>
            {discount && <PriceDiscount oldPrice={discount.oldPrice} percent={discount.percent} />}
          </div>
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className={classnames(styles['description'], !transport && styles['two-rows'])}
          >
            {description}
          </a>
          {transport && (
            <UIText2 display="inline" whiteSpace="nowrap">
              {transport}
            </UIText2>
          )}
        </>
      </ActionAfterViewObserver>
    </a>
  );
};

// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  IDesktopGetRecommendationsRequest,
  TDesktopGetRecommendationsModel,
  IMappers,
  TDesktopGetRecommendationsResponse,
  TDesktopGetRecommendationsResponse_1,
  TDesktopGetRecommendationsResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TDesktopGetRecommendationsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'feed-page',
  pathApi: '/v2/desktop/get-recommendations/',
} as TDesktopGetRecommendationsModel;

export function createDesktopGetRecommendationsModel(
  parameters: IDesktopGetRecommendationsRequest,
): TDesktopGetRecommendationsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IDesktopGetRecommendationsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IDesktopGetRecommendationsRequest;
}

export async function fetchDesktopGetRecommendations<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IDesktopGetRecommendationsOptions<TResponse200, TResponse400>): Promise<
  TDesktopGetRecommendationsResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createDesktopGetRecommendationsModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TDesktopGetRecommendationsResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TDesktopGetRecommendationsResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TDesktopGetRecommendationsResponse;
}

// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  IGetPromoBannersRequest,
  TGetPromoBannersModel,
  IMappers,
  TGetPromoBannersResponse,
  TGetPromoBannersResponse_1,
  TGetPromoBannersResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TGetPromoBannersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'newbuilding-promos',
  pathApi: '/v1/get-promo-banners/',
} as TGetPromoBannersModel;

export function createGetPromoBannersModel(parameters: IGetPromoBannersRequest): TGetPromoBannersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetPromoBannersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetPromoBannersRequest;
}

export async function fetchGetPromoBanners<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetPromoBannersOptions<TResponse200, TResponse400>): Promise<
  TGetPromoBannersResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetPromoBannersModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TGetPromoBannersResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TGetPromoBannersResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetPromoBannersResponse;
}

import { ca } from '@cian/analytics';
import { EQueryParameters } from '../repositories/feed-page/v2/mobile-site/get-recommendations';
import { TDeviceType } from '@cian/ui-kit';
import { TProduct } from '../types/recommendation';

type TAnalyticsParameters = {
  label: string;
  deviceType: TDeviceType;
  landingType: EQueryParameters;
  products?: TProduct[];
};

const PLACEMENT = {
  [EQueryParameters.FlatRent]: 'sale',
  [EQueryParameters.FlatRentForDay]: 'dailyrent',
  [EQueryParameters.MainPage]: 'mainpage',
  [EQueryParameters.SecondaryFlatSale]: 'sale',
  [EQueryParameters.SuburbanSale]: 'suburban',
};

export function trackAddToFavorite({ deviceType, label, landingType, products }: TAnalyticsParameters) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'favorite',
    action: 'add',
    label,
    page: {
      pageType: 'Home',
      siteType: deviceType === 'phone' ? 'mobile' : deviceType,
      extra: {
        lending_main_page: true,
        placement: PLACEMENT[landingType],
      },
    },
    products,
  });
}

export function trackDeleteFromFavorite({ deviceType, label, landingType, products }: TAnalyticsParameters) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'favorite',
    action: 'remove',
    label,
    page: {
      pageType: 'Home',
      siteType: deviceType === 'phone' ? 'mobile' : deviceType,
      extra: {
        lending_main_page: true,
        placement: PLACEMENT[landingType],
      },
    },
    products,
  });
}

import * as cx from 'classnames';
import * as React from 'react';
import { Image, UIHeading2, UIText1 } from '@cian/ui-kit';
import { TBannerTheme } from '../utils/bannerTypeToTheme';

import * as styles from './Banner.css';

export interface IBanner {
  title: string;
  subtitle: string;
  customClassName?: string;
  customContent?: React.ReactElement;
  footer: React.ReactElement;
  labels: React.ReactElement | null;
  imageUrl?: string | null;
  linkUrl?: string;
  theme: TBannerTheme;
  onClick(): void;
}

export const Banner = ({
  title,
  subtitle,
  labels,
  imageUrl,
  customClassName,
  customContent,
  footer,
  theme,
  linkUrl,
  onClick,
}: IBanner) => {
  const newlineTitle = title.split(`\\n`).map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ));

  return (
    <div
      data-testid="NewbuildingBanner"
      data-name="NewbuildingBanner"
      className={cx(styles['banner'], styles[theme], customClassName)}
    >
      {linkUrl && <a className={styles['link']} href={linkUrl} onClick={onClick} target="_blank" rel="noreferrer"></a>}
      {customContent ?? (
        <div className={styles['content']}>
          {imageUrl && (
            <div className={styles['illustration']}>
              <Image width="56px" height="56px" src={imageUrl} />
            </div>
          )}
          <div className={styles['description']}>
            {labels && <div className={styles['labels']}>{labels}</div>}
            <div className={styles['title']}>
              <UIHeading2 color="current_color">{newlineTitle}</UIHeading2>
            </div>
            <UIText1 color="current_color">{subtitle}</UIText1>
          </div>
        </div>
      )}
      <div className={styles['footer']}>{footer}</div>
    </div>
  );
};

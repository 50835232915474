// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TDeleteOfferFromFavoritesRequest,
  TDeleteOfferFromFavoritesModel,
  IMappers,
  TDeleteOfferFromFavoritesResponse,
  TDeleteOfferFromFavoritesResponse_1,
  TDeleteOfferFromFavoritesResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TDeleteOfferFromFavoritesModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'favorites',
  pathApi: '/v1/delete-offer-from-favorites/',
  requestType: 'json',
} as TDeleteOfferFromFavoritesModel;

export function createDeleteOfferFromFavoritesModel(
  parameters: TDeleteOfferFromFavoritesRequest,
): TDeleteOfferFromFavoritesModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IDeleteOfferFromFavoritesOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TDeleteOfferFromFavoritesRequest;
}

export async function fetchDeleteOfferFromFavorites<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IDeleteOfferFromFavoritesOptions<TResponse200, TResponse400>): Promise<
  TDeleteOfferFromFavoritesResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createDeleteOfferFromFavoritesModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TDeleteOfferFromFavoritesResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TDeleteOfferFromFavoritesResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TDeleteOfferFromFavoritesResponse;
}

import * as React from 'react';
import { Recommendation } from '../../../components/Recommendation';
import { FavoriteButtonContainer } from '../../FavoriteButtonContainer';
import { LabelsContainer } from '../../LabelsContainer';
import { RecommendationsList } from '../../../components/RecommendationsList';

import type { useRecommendations } from '../useRecommendations';

type TRecommendationsListMobileProps = {
  onTrack: (offerId: number | null, position: number) => void;
  recommendationsControls: ReturnType<typeof useRecommendations>;
};

export const RecommendationsListMobile: React.FC<TRecommendationsListMobileProps> = props => {
  const {
    recommendationsControls: {
      onShow,
      showHeader,
      onMoreClick,
      recommendations,
      onRecommendationClick,
      onShowTrap,
      isOfferVisited,
    },
    onTrack,
  } = props;

  return (
    <RecommendationsList onShow={onShow} onMoreClick={onMoreClick} withHeader={showHeader}>
      {recommendations.map((recommendation, index) => (
        <Recommendation
          onTrack={onTrack}
          position={index + 1}
          key={`recommendation-${index}`}
          onClick={onRecommendationClick}
          onShowTrap={onShowTrap}
          favoriteButton={<FavoriteButtonContainer size="XS" id={recommendation.offerId} />}
          labels={<LabelsContainer visited={isOfferVisited(recommendation.offerId)} />}
          {...recommendation}
        />
      ))}
    </RecommendationsList>
  );
};

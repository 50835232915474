import { IRecommendations } from '../../../types/newbuildingRecommendations';
import { TBanners } from '../../../types/newbuildingBanners/banners';

// TODO: Удалить в CD-231816 баннер флайта и при аппруве баннер КпН.
const banners = [
  'lastChanceDiscount',
  'topPromos',
  'topPromo',
  'allPromos',
  'kpn',
  'promosFlight',
  'newbuildingBroker',
];

export const isNewbuildingBanner = (item: IRecommendations | TBanners): item is TBanners => {
  return !!(item.type && banners.includes(item.type));
};

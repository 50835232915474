import { ResponseError } from '../../errors';

import { IApplicationContext } from '../../types/applicationContext';
import {
  fetchGetUserRecommendations,
  type TGetUserRecommendationsResponse,
} from '../../repositories/newbuilding-search/v1/get-user-recommendations';
import { INewbuildingsRecommendations } from '../../types/newbuildingRecommendations';

const DEGRADATION_TELEMETRY_KEY = 'degradation.recommendations.desktop';

type TGetRecommendationsParams = {
  cookies?: string;
};

export async function getNewbuildingRecommendations(
  context: IApplicationContext,
  { cookies }: TGetRecommendationsParams,
): Promise<INewbuildingsRecommendations> {
  const {
    httpApi,
    logger,
    telemetry,
    custom: { subdomain },
  } = context;

  try {
    const response: TGetUserRecommendationsResponse = await fetchGetUserRecommendations({
      httpApi,
      config: {
        requestConfig: {
          headers: cookies ? [['Cookie', cookies]] : [],
        },
      },
      parameters: {
        subdomain,
      },
    });

    if (response.statusCode !== 200) {
      throw new ResponseError({
        domain: 'getDesktopRecommendations',
        message: response.response.message || 'Код ответа не 200',
        details: { errors: JSON.stringify(response.response.errors) },
      });
    }

    return response.response;
  } catch (error) {
    telemetry.increment(DEGRADATION_TELEMETRY_KEY);
    logger.warning(error, {
      domain: 'getNewbuildingRecommendations',
      message: 'Не удалось получить список рекомендаций',
    });

    // Повторное выкидывание исключения нужно для asyncThunk
    throw error;
  }
}

import { IAppState } from '../../types/redux';

import { selectExperiments } from '../selectExperiments';
import { isAbUseExperimentGroup } from '../../utils/isAbUseExperimentGroup';

// TODO: Удалить эксперимент в CD-231816.
export const NEWBUILDING_BROKER_BANNER_TEXT = 'newbiulding_booking_main_page_desktop';

export const selectNewbuildingBrokerExperimentEnabled = (state: IAppState): boolean => {
  const abUseExperiments = selectExperiments(state);

  return isAbUseExperimentGroup(abUseExperiments, NEWBUILDING_BROKER_BANNER_TEXT, 'B');
};

import { v4 as uuid } from 'uuid';

import { ISession } from '../types';

const SESSION_KEY = '__recs_ml_search_session__';

export function generateSession(): ISession {
  const guid = uuid();
  const session = { guid };

  try {
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(session));
    // eslint-disable-next-line no-empty
  } catch (error) {}

  return session;
}

export function restoreSession(): ISession | null {
  try {
    const sessionString = sessionStorage.getItem(SESSION_KEY);
    const session = sessionString ? JSON.parse(sessionString) : null;

    return session;
  } catch (error) {
    return null;
  }
}

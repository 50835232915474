import { EQueryParameters } from '../../repositories/feed-page/v2/mobile-site/get-recommendations';

const Placement = {
  [EQueryParameters.FlatRent]: 'sale',
  [EQueryParameters.SecondaryFlatSale]: 'sale',
  [EQueryParameters.SuburbanSale]: 'suburban',
  [EQueryParameters.FlatRentForDay]: 'dailyrent',
  [EQueryParameters.MainPage]: 'mainpage',
};

export const getPageExtra = (landingType: EQueryParameters) => {
  return {
    lending_main_page: true,
    placement: Placement[landingType],
  };
};

import { IRecItemV2Schema } from '../repositories/feed-page/entities/recommendations/RecItemV2Schema';
import { TAnalyticsData, TRecommendation } from '../types/recommendation';

export function prepareRecommendations(rawRecommendations?: IRecItemV2Schema[]): TRecommendation[] {
  if (!rawRecommendations) {
    return [];
  }

  return rawRecommendations.map(
    (
      {
        offerId,
        url,
        imageUrl,
        info,
        subtitle,
        title,
        underground,
        analyticsData = {},
        isFavorite,
        dealType,
        entityType,
        discount,
      },
      index,
    ) => ({
      description: subtitle || '',
      href: url || '',
      imageUrl: imageUrl || '',
      title: title || '',
      transport: info,
      analyticsData: {
        offerId,
        type: offerId ? 'offer' : 'trap',
        ...analyticsData,
        products: offerId
          ? [
              {
                id: offerId,
                position: index + 1,
                offerType: 'offer',
              },
            ]
          : undefined,
      } as TAnalyticsData,
      offerId: offerId || null,
      underground,
      isFavorite: isFavorite || false,
      dealType,
      entityType,
      discount,
    }),
  );
}

import * as React from 'react';
import { LinkButton, Theme } from '@cian/ui-kit';
import { Banner } from '../Banner/Banner';

interface IAllNewbuildingsBannerProps {
  url: string;
  onClick(): void;
}

export const AllNewbuildingsBanner = ({ url, onClick }: IAllNewbuildingsBannerProps) => {
  return (
    <Banner
      imageUrl="https://static.cdn-cian.ru/frontend/newbuilding-b2c-assets/banners/allNewbuildinsBanner.svg"
      onClick={onClick}
      linkUrl={url}
      theme="primary-basic"
      labels={null}
      subtitle=""
      footer={
        <Theme theme="colored">
          <LinkButton href={url} target="_blank" onClick={onClick} fullWidth={true}>
            Все предложения
          </LinkButton>
        </Theme>
      }
      title="Смотреть все предложения"
    />
  );
};

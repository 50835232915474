import { ISession } from './types';
import { assertBrowser } from './utils/assertBrowser';
import { assertSession } from './utils/assertSession';
import { restoreSession, generateSession } from './utils/sessionApi';

/**
 * @description MlSearchSession хранит сессию поиска для ml-ранжирования.
 * Сессия поиска нужна для того, чтобы связывать поиски объявлений пользователя с одинаковыми фильтрами.
 * Сессия описывается 36-значным уникальным идентификатором и хранится в sessionStorage.
 * MlSearchSession должен использоваться ТОЛЬКО на стороне браузера, поскольку,
 * во-первых, он напрямую связан с sessionStorage, а во-вторых,
 * он хранит уникальную сессию для конкретного пользователя.
 **/

export class MlSearchSession {
  private session: ISession;

  public init() {
    assertBrowser();

    const session = restoreSession();

    if (session) {
      this.session = session;
    } else {
      this.session = generateSession();
    }
  }

  public getSessionGuid(): string {
    assertSession(this.session);

    return this.session.guid;
  }
}

export const mlSearchSession = new MlSearchSession();
